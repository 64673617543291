// main: ../app.scss

// BS overrides
$primary:       #676C72;
$secondary:     #CFCFCD;
$success:       #251101;
$info:          #4DCCBD;
$warning:       #F6AE2D;
$danger:        #DB3069;
$dark:          #000000;
$light:         #B3B2B2;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);


$body-color:                $dark;

$link-color:                $dark;
$link-decoration:           underline;
$link-hover-color:          darken($primary, 10%);
$link-hover-decoration:     underline;


$font-family-sans-serif: 'Poppins',  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$headings-font-family: 'Poppins-SemiBold',  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// $headings-font-weight:        700;
$headings-line-height:        1.2;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.35;
$h5-font-size:                $font-size-base * 1.2;
$h6-font-size:                $font-size-base;

$enable-responsive-font-sizes:                true;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1600px
);

// Grid containers
// Define the maximum width of `.container` for different screen sizes.

$navbar-padding-x:   0;

$blockquote-small-color:      $dark;

$enable-caret:                false;

$modal-content-border-radius:       0;
$modal-header-border-color:         #fff;
$modal-footer-border-color:         $modal-header-border-color;
$modal-header-border-width:         0;
$modal-footer-border-width:         0;

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;

$input-border-radius:                           $border-radius;
$input-border-radius-lg:                        $border-radius-lg;
$input-border-radius-sm:                        $border-radius-sm;

$btn-border-radius:                             $border-radius;
$btn-border-radius-lg:                          $border-radius-lg;
$btn-border-radius-sm:                          $border-radius-sm;

$custom-checkbox-indicator-border-radius:       $border-radius;
$custom-control-indicator-checked-bg:           $primary;
$custom-control-indicator-checked-border-color: $primary;
$custom-control-indicator-border-color: 		$primary;
$custom-control-indicator-size:         		1.5rem;

$custom-select-border-radius:                   $border-radius;

$nav-tabs-border-radius:                        $border-radius;

$nav-pills-border-radius:                       $border-radius;

$dropdown-border-radius:                        $border-radius;

$pagination-border-radius-sm:                   $border-radius-sm;
$pagination-border-radius-lg:                   $border-radius-lg;

$card-border-radius:                            $border-radius;

$tooltip-border-radius:                         $border-radius;

$popover-border-radius:                         $border-radius-lg;

$badge-border-radius:                           $border-radius;

$modal-content-border-radius:                   $border-radius-lg;

$alert-border-radius:                           $border-radius;

$progress-border-radius:                        $border-radius;

$list-group-border-radius:                      $border-radius;

$thumbnail-border-radius:                       $border-radius;

$breadcrumb-border-radius:                      $border-radius;

$input-height: calc(#{$input-line-height * 1.5em} + #{$input-padding-y * 2} + #{$input-height-border});

$modal-xl:                         calc(100% - 2rem);
$modal-lg:                         calc(100% - 2rem);
$modal-md:                         calc(100% - 2rem);
$modal-sm:                         calc(100% - 2rem);
