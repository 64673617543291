@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
