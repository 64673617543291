* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html {
	min-height: 100%;
}
html, body {
	max-width: 100%;
	min-height: 100%;
}
body {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	-webkit-animation: fadein .2s;
	animation: fadein .2s;
	font-family: $font-family-sans-serif;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5.h5,h6,.h6 {
	letter-spacing: -.05rem;
}
strong {
	font-family:$headings-font-family;
}
a, .btn-link {
	text-decoration-color: $light;
	text-underline-offset: 0.5rem;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
	padding-left: 1rem;
	padding-right: 1rem;
	@include media-breakpoint-up(lg) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
@include media-breakpoint-up(sm) {
  .container, .container-sm {
    max-width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .container, .container-sm, .container-md {
    max-width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 100%;
  }
}

@include media-breakpoint-up(xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1400px;
  }
}
.row {
  margin-right: -1rem;
  margin-left: -1rem;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  padding-right: 1rem;
  padding-left: 1rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
	.sticky {
		position: -webkit-sticky;
		position: sticky;
		z-index: 3;
		top:0;
		transition: top 0.3s;
		// margin-bottom: 1.8rem;
	}
	.sticky:before,
	.sticky:after {
		content: '';
		display: table;
	}
}
.btn-space-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
