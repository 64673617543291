.swiper-wrapper {
    // justify-content: center;
    align-items: center;
}
.swiper-container {
    width: 100%;
    height: 100%;
    background-color:$primary;
    margin-bottom: 2rem;
}

.swiper-slide {
    text-align: center;
    // font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 50vh;
    // object-fit: cover;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
}
