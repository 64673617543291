.form-group {
	display: flex;
	justify-content: space-between;
}
.form-control, .custom-select {
	background-color: rgba(#fff, 0);
	// font-size: 1.2rem;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid rgba($dark, .2);
	&:focus {
		color: $black;
		background-color: rgba(#fff,.1);
		border-bottom-color: $info;
		outline: 0;
		box-shadow: none;
	}
}
.form-control.is-invalid, .was-validated .form-control:invalid,
.form-control.is-valid, .was-validated .form-control:valid {
	background-position: 98% calc(0.75em + .1875rem);
}

.custom-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url('../img/chevron.svg');
	background-repeat: no-repeat;
	background-position:right .75rem center;
	background-size: 1rem 1rem;
	// padding: 1.5rem .5rem .15rem;
	line-height: 1.5;
	height: auto;
	.custom-select-label {
		margin-top: -5px;
	}
}

label {
	font-size: .8rem;
	line-height: 1.2;
	width: 100%;
}

.floating-label {
	margin-top: .25rem;
	position:  relative;
	// font-size: .8rem;
	// margin-bottom: 2rem;
	label {
		position:  absolute;
		margin-top: 0;
		top: 1.5rem;
		left:  .75rem;
		opacity:  0;
		transition:  all .3s ease;
		z-index: -1;
		width: auto;
	}
	select {
		&:not([data-chosen] + label) {
			padding: .88rem .5rem .88rem;
		}
		&[data-chosen] {
			padding: 1.2rem .7rem .275rem !important;
		}
	}
	input:not(:placeholder-shown) {
		padding:  1.2rem .75rem .15rem;
	}
	textarea {
		padding-top: .8rem;
	}
	input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) + label, select[data-chosen] + label {
		transform:  translateY(-18px);
		opacity:  1;
		z-index: 0;
	}
	&.search-input-group {
		background-color: rgba(#fff, 1);
		border-radius:.5rem;
		.form-control {
			border-radius: .5rem 0 0 .5rem;
			padding-left: 1rem;
		}
		label {
			left:1rem;
		}
		.input-group-append>.btn {
			border-radius: 0 .5rem .5rem 0;
		}
	}
}
.custom-checkbox, .range-group, .has-tooltip {
	svg {
		circle {
			fill:$light;
		}
		path {
			fill:$dark;
		}
	}
	&:hover {
		svg {
			circle {
				fill:$info;
			}
			path {
				fill:$dark;
			}
		}
	}
}
.custom-checkbox {
	display: flex;
	align-items: center;
}
.custom-control-label {
	margin-top: 0;
	font-size: .8rem;
	cursor: pointer;
	span {
		position: relative;
		top:.25rem;
	}
}
.custom-control-label::before {
	background-color: rgba($info,1);
	border: rgba($info,1) solid 1px;
}
.custom-control-input:checked ~ .custom-control-label::before {
	background-color: $success !important;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: $info !important;
	border:1px solid rgba($info,.2);
	box-shadow: 0px 0px 0px 6px rgba($info,.4);
	transition: all .3s ease-in-out;
}
.custom-switch .custom-control-label::after {
	background-color: #fff;
}
.password-input {
	flex: 1 1 calc(100% - 2rem);
}
.preview-icon {
	// flex: 0 0 2rem;
	// width: 2rem;
	// height: 2rem;
	appearance:none;
	background-color: transparent;
	border: none;
	// padding: 0;
	// margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
}
.eye {
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.eye-open, .eye-closed {
	&:before {
		width: 2rem;
		height: 2rem;
	}
}
.eye-open {
	&:before {
		content: url(../img/eye-open.svg);
	}
}
.eye-closed {
	&:before {
		content: url(../img/eye-closed.svg);
	}
}
