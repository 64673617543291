// Keep footer at bottom
footer {
	background-color: $light;
	flex-shrink: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
	.row {
		align-items: center;
	}
	.logo {
		display: flex;
		align-items: center;
		svg {
			fill:$success;
			max-width: 12rem;
			flex: 0 0 12rem;
		}
	}
	@include media-breakpoint-up(sm) {
		.logo {
			svg {
				margin-left: auto;
			}
		}
	}
}
