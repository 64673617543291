.app-input--disabled {
  &:hover {
    cursor: no-drop;
  }
}

.app-input--error {
  border-color: red !important;
}

.form-checkbox {
  display: inline-flex;

  .form-control-plaintext {
    width: fit-content;
  }

  input {
    margin-top: 0.50rem;
    margin-left: 1rem;
  }
}

.response {
  margin-top: 1.20rem;
  margin-left: 1rem;
}

.custom-checkbox {
  flex-direction: column-reverse;
  .error__label {
    margin-top: 1rem;
  }
}

