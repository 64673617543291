.banner-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px;
  z-index: 999;
  background-color: #212529;
  text-align: center;

  .banner-content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1000px;

    .text {
      color: #fff;

      .link {
        text-decoration: underline;
        cursor: pointer;
        color: #fff;
      }
    }

    button {
      border-color: #fff;
      color: #fff;
    }
  }
}