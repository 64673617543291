// main: ../app.scss
.navbar {
	transition: all 0.3s ease-out;
	line-height: 1;
	padding-left: 1rem;
	padding-right: 1rem;
	.navbar-brand{
		padding:1rem 1rem .8rem 0;
		svg {
			transition: all 0.3s ease-out;
			fill:$primary;
			width:14rem;
			height: auto;
		}
	}
	.navbar-collapse {
		width: 100%;
		background: $primary;
		position: fixed;
		display: block;
		visibility: hidden;
		opacity: 0;
		z-index: -1;
		top: var(--navbar-height);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.4s;

		.navbar-nav {
			text-align: center;
			position: absolute;
			padding-top: calc(var(--navbar-height) / 2);
			padding-bottom: var(--navbar-height);

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			transition: opacity 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
			padding: 0;
			.nav-items {
				opacity: 0;
				transition: opacity .5s ease-out;
			}
		}
		&.show {
			min-height: 100vh;
			visibility: visible;
			opacity: 1;
			z-index: 9;
			.nav-items {
				opacity: 1;
			}
		}

	}

	.navbar-toggler.collapsed ~ .navbar-collapse {
		opacity: 0;
		transition: opacity 300ms ease-out;
	}
	.navbar-toggler {
		display: block;
		z-index: 10;
		border: none;
		padding: 1rem .5rem;
		&:focus {
			outline: none;
		}
		&[aria-expanded="true"] {
			.icon-bar {
				background-color: #fff;
				&:nth-of-type(1) {
					transform: translate3d(1px,0,0) rotate(-45deg);
					transform-origin: center center;
					width: 11px;
				}
				&:nth-of-type(2) {
					transform: rotate(-90deg);
					transform-origin: center center;
				}
				&:nth-of-type(3) {
					transform: translate3d(0px,-14px,0) rotate(-135deg);
					transform-origin: right top;
					width: 11px;
				}
			}
		}
		.icon-bar {
			width: 22px;
			display: block;
			height: 2px;
			background-color: $primary;
			-webkit-transition: all 0.2s;
			transition: all 0.2s;
			&+ .icon-bar {
				margin-top: 4px;
			}
			&:nth-of-type(1) {
				transform: translate3d(0,0,0) rotate(0deg);
				width: 22px;
			}
			&:nth-of-type(2) {
				transform: translate3d(0,0,0) rotate(0deg);
			}
			&:nth-of-type(3) {
				transform: translate3d(0,0,0) rotate(0deg);
				width: 22px;
			}
		}
		&.collapsing {
			opacity: 1;
			transition: opacity .3s ease-out;
		}
	}
	@include media-breakpoint-up(lg) {
		--navbar-height: 122px;
		padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 2rem;
		padding-bottom: 1rem;
		transition: all 0.3s ease-out;
	}


	&.nav-scrolled {

		// --navbar-hHe eight: 74px;
		height: 74px;
		background-color: rgba($secondary,.95);
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			svg {
				width:12rem;
				height: auto;
				fill:$success;
			}
		}
	}
	.nav-link, .dropdown-item {
		padding-left: .8rem;
		padding-right: .8rem;
		padding-top: .8rem;
		padding-bottom: .8rem;
		transition: opacity .3s ease-out;
		color: #fff;
		font-size: 1.5rem;
		outline: none;
		&:hover {
			opacity: .75;
		}
	}
	.dropdown-item {
		font-size: 1.2rem;
		&.active, &:active, &:focus, &:hover  {
			background-color: transparent;
		}
	}
	.dropdown-menu {
		background-color: transparent;
		border:none;
		text-align: center;
		padding: 0;
	}
	.select-language {
		margin-top: 2rem;
		border-bottom-color:rgba(#fff, .5);
		border-top:1px solid rgba(#fff, .5);
		color:#fff;
		font-size: 1.5rem;
		background-color: transparent;
		background-image: url('../img/chevron-white.svg');
		padding-right: 2.5rem;
		&:focus {
			color: #fff;
			background-color: transparent;
			border-bottom-color: #fff;
			border-top:1px solid #fff;
			outline: 0;
			box-shadow: none;
		}
	}
}
