// Color utilities
.primary-color {color:$primary;}
.secondary-color {color:$secondary;}
.success-color {color:$success;}
.info-color {color:$info;}
.warning-color {color:$warning;}
.danger-color {color:$danger;}
.dark-color {color:$dark;}
.light-color {color:$light;}

.primary-bg {background-color:$primary;}
.secondary-bg {background-color:$secondary;}
.success-bg {background-color:$success;color:#fff;}
.info-bg {background-color:$info;color:#fff;}
.warning-bg {background-color:$warning;color:#fff;}
.danger-bg {background-color:$danger;color:#fff;}
.dark-bg {background-color:$dark;color:#fff;}
.light-bg {background-color:$light;}
