.product-buttons, .product-video, .product-links {
	margin-bottom: 2rem;
}
.fab {
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	border: none;
	appearance:none;
	background-color: rgba($info,.95);
	fill:#fff;
	width:3rem;
	height: 3rem;
	z-index: 1;
	svg {
		transition: transform .5s ease-out;
	}
	&:hover {
		background-color: rgba($danger,.95);
		svg {
			transform: rotate(180deg);
		}
	}
}
.close {
	img {
		position: relative;
		z-index: -1;
	}
}
.modal-body {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 575px) {
	.product-buttons > a {
		display: block;
		+ a {
			margin-left: 0 !important;
			margin-top: 10px;
		}
	}
}