.tabs-section {

    .nav-link {
        position: relative;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 2.5;
        border: 1px solid $primary;
        &.active {
            color:#fff;
            &::after {
                transform: translate3d(0,0,0);
            }
        }
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            height: calc(100% + 1px);
            border: 1px solid $primary;
            background-color: $primary;
            content: '';
            transition: transform 0.3s;
            transform: translate3d(0,100%,0);
        }
    }
    .tab-pane {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}
