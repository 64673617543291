.favorite {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    margin-bottom: 1rem;
    &:first-child {
        padding-top: 0
    }
}
.favorite-img {
    height: 4rem;
    width: 4rem;
    flex: 0 0 4rem;
    margin-right: 1rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-up(sm) {
        height: 6rem;
        width: 6rem;
        flex: 0 0 6rem;
    }
    @include media-breakpoint-up(md) {
        height: 8rem;
        width: 8rem;
        flex: 0 0 8rem;
    }
}
.favorite-info {
    h3 {
        margin-bottom: .25rem;
    }
    padding-right: .8rem;
    p {
        line-height: 1.2;
    }
    a {
        text-decoration: none;
    }
}
.favorite-store {
    p {
        margin-bottom: 0;
    }
}
.favorite-remove {
    margin-left: auto;
}
