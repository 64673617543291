main {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	top:0;
	left: 0;
	z-index: 2;
	padding-top:var(--navbar-height);
	padding-bottom:var(--navbar-height);
	display: flex;
	flex-direction: column;
	flex:1 0 auto;
	justify-content: center;
	background: white;
	position: relative;
	z-index: 1;
	box-shadow:
		0px 0px 5px 0px rgba($dark,0.4),
		0px 0px 16px -8px rgba($dark,0.2);
}
