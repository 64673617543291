.app-input--disabled {
  &:hover {
    cursor: no-drop;
  }
}

.app-input--error {
  border-color: red !important;
}

.form-group {
  flex-direction: column;
}

::-ms-reveal {
  display: none;
}